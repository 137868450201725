import {Options, Vue} from "vue-class-component";
import {NotificationsRoutesEnum} from "../../router";
import {SessionStorageEnum} from "@/utils/SessionStorageEnum";

import {
    AppTable, AssignNotificationDialog, YesNo,
} from "@components";
import Table from "@components/AppTable/Table";
import {TicketsRoutesEnum} from "@/modules/tickets/router";
import {messagesService} from "@services/messages.service";
import {FilterMatchMode} from "primevue/api";
import {NotificationFilters} from "./NotificationFilters";
import moment from "moment";
import {UsersRoutesEnum} from "@/modules/users/router";
import {NotificationTypeEnum} from "@/model/enums/NotificationTypeEnum";
import {authStore} from "@/modules/auth/store";
import {NotificationStatusEnum} from "@/model/enums/NotificationStatusEnum";
import NotificationsFilters from "@/modules/notifications/pages/notifications/NotificationsFilters.vue";
import {UserRoleEnum} from "@/model/enums/UserRoleEnum";
import {ShiftsRoutesEnum} from "@/modules/shifts/router";

@Options({
    components: {
        AssignNotificationDialog,
        AppTable,
        YesNo,
        NotificationsFilters,
    },

    beforeRouteEnter(to, from, next) {
        if (from.name && !(from.name as string).startsWith(NotificationsRoutesEnum.NOTIFICATIONS_PREFIX)) {
            sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_NOTIFICATIONS);
            sessionStorage.removeItem(SessionStorageEnum.NOTIFICATIONS_ADVANCED_FILTERS);
        }

        next();
    },

    beforeRouteLeave() {
        if (this.service?.cancelPendingRequests) {
            this.service.cancelPendingRequests();
        }
    },
})
export default class NotificationsPage extends Vue {
    statuses: any[] = []
    filters: any = null;
    types: any = []
    advFilters: NotificationFilters = new NotificationFilters();
    selectedNotificationId: any = null;
    displayNotificationDialog: boolean = false;

    get service() {
        return messagesService;
    }

    get stateKey() {
        return SessionStorageEnum.TABLE_STATE_NOTIFICATIONS
    }

    onReset() {
        this.advFilters = new NotificationFilters();
    }

    onFiltersChange() {
        const table = (this.$refs.dataTable as Table);

        if (table?.filters) {
            const filters = table.filters;
            if (table?.filters) {

                if (this.advFilters.from && this.advFilters.to) {
                    const from = moment(this.advFilters.from);
                    const to = moment(this.advFilters.to);

                    filters.created_at.value = [from, to];

                    filters.created_at.matchMode
                        = FilterMatchMode.BETWEEN;
                } else {
                    filters.created_at.value
                        = moment(this.advFilters.from);

                    filters.created_at.matchMode
                        = FilterMatchMode.GREATER_THAN_OR_EQUAL_TO;
                }
            }

        }

        table.applyFilter();
    }

    get table() {
        return this.$refs.dataTable as Table;
    }

    rowClass(notification: any) {
        return !notification.is_read ? 'not-read' : null;
    }

    private initFilter() {
        const {from, to} = this.advFilters;

        const isValidRange = from && to;

        this.filters = {
            created_at: {
                value: isValidRange ? [from, to] : from,
                matchMode: isValidRange
                    ? FilterMatchMode.BETWEEN
                    : FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
            },
            status: {
                value: [],
                matchMode: FilterMatchMode.IN
            },
            user_owner_name: {
                value: null,
                matchMode: FilterMatchMode.STARTS_WITH
            },
            type: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
            is_read: {
                value: null,
                matchMode: FilterMatchMode.EQUALS
            },
        };
    }

    update() {
        this.table.applyFilter();
    }

    changeStatus(message) {
        if (message.status !== message.oldStatus) {

            this.$confirm.require({
                header: "Confirmation requested",
                message: 'Do you want to change status for this notification?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.$waitFor(
                        async () => {
                            await messagesService.changeStatus(message.id, message.status)
                            message.oldStatus = message.status
                            this.$successMessage('Status changed successfully');
                        },
                        this.$t('Operation failed')
                    )
                },
                reject: () => {
                    message.status = message.oldStatus
                },
                onHide: () => {
                    message.status = message.oldStatus
                }
            });
        }
    }

    created() {
        for (const item in NotificationStatusEnum) {
            if (isNaN(Number(item))) {
                this.statuses.push({
                    name: this.$t(`notification.statuses.` + item),
                    code: NotificationStatusEnum[item]
                })
            }
        }
        this.types = [
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DISPUTE),
                code: NotificationTypeEnum.DISPUTE
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DELETED_BY_USER),
                code: NotificationTypeEnum.DELETED_BY_USER
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.FREE_DUMP_REQUESTED),
                code: NotificationTypeEnum.FREE_DUMP_REQUESTED
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DUMP_IN_FREE_DUMP_ZONE),
                code: NotificationTypeEnum.DUMP_IN_FREE_DUMP_ZONE
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DRIVER_CHANGE_BROKE_REQUEST),
                code: NotificationTypeEnum.DRIVER_CHANGE_BROKE_REQUEST
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DRIVER_SIGNUP),
                code: NotificationTypeEnum.DRIVER_SIGNUP
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DRIVER_WORK_NOT_STARTED),
                code: NotificationTypeEnum.DRIVER_WORK_NOT_STARTED
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.DRIVER_UPLOADED_TICKET_FILE),
                code: NotificationTypeEnum.DRIVER_UPLOADED_TICKET_FILE
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.SHIFT_CANCEL_TRUCKS),
                code: NotificationTypeEnum.SHIFT_CANCEL_TRUCKS
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.SHIFT_CHANGE_QTY),
                code: NotificationTypeEnum.SHIFT_CHANGE_QTY
            },
            {
                name: this.$t(`notification.list.type_` + NotificationTypeEnum.SHIFT_CHANGE_LOAD_DUMP),
                code: NotificationTypeEnum.SHIFT_CHANGE_LOAD_DUMP
            }
        ]
        this.initFilter();
    }

    readNotification(item) {
        this.$waitFor(
            async () => {
                await messagesService.read(item.id);
                this.update()
            },
            this.$t('Operation failed')
        );
    }

    get me() {
        return authStore.getters.me
    }

    get imAdmin() {
        return authStore.getters.me.role === UserRoleEnum.SUPER_ADMIN
    }

    actualChangingOwner(user_id = authStore.getters.me.id){

        this.$waitFor(
            async () => {
                await messagesService.changeOwner(this.selectedNotificationId, user_id)
                this.update()
                this.$successMessage('Owner changed successfully');
            },
            this.$t('Operation failed')
        )
    }

    changeOwner(id) {
        this.selectedNotificationId = id

        if(this.imAdmin){
            this.displayNotificationDialog = true;
        } else {
            this.$confirmMessage("Do you want to take charge of this ticket?")
                .then(r => {
                    if (r) {
                        this.actualChangingOwner()
                    }
                })
        }


    }

    goToDetails(item) {
        let routeData
        if (item.type === NotificationTypeEnum.DRIVER_SIGNUP || item.type === NotificationTypeEnum.DRIVER_CHANGE_BROKE_REQUEST) {
            routeData = this.$router.resolve({
                name: UsersRoutesEnum.USER_DETAIL,
                params: {userId: item.destination_id}
            });
        } else if(item.type === NotificationTypeEnum.DRIVER_UPLOADED_TICKET_FILE) {
            routeData = this.$router.resolve({
                name: TicketsRoutesEnum.TICKETS_GALLERY,
                params: {ticketId: item.destination_id}
            });
        } else if(item.type === NotificationTypeEnum.SHIFT_CHANGE_LOAD_DUMP ||
            item.type === NotificationTypeEnum.SHIFT_CHANGE_QTY ||
            item.type === NotificationTypeEnum.SHIFT_CANCEL_TRUCKS
        ) {
            routeData = this.$router.resolve({
                name: ShiftsRoutesEnum.SHIFTS_DETAIL,
                params: {shiftId: item.destination_id}
            });
        } else {
            routeData = this.$router.resolve({
                name: TicketsRoutesEnum.TICKETS_DETAIL,
                params: {ticketId: item.destination_id}
            });
        }
        window.open(routeData.href, '_blank');

        this.$waitFor(
            async () => {
                await messagesService.read(item.id);
                this.update()
            },
            this.$t('Operation failed')
        );

        messagesService.read(item.id)
    }

}
