export enum NotificationTypeEnum {
    DISPUTE = 10,
    DELETED_BY_USER = 20,
    FREE_DUMP_REQUESTED = 30,
    DUMP_IN_FREE_DUMP_ZONE = 40,
    DRIVER_CHANGE_BROKE_REQUEST = 50,
    DRIVER_SIGNUP = 60,
    DRIVER_WORK_NOT_STARTED = 70,
    DRIVER_UPLOADED_TICKET_FILE = 80,
    SHIFT_CHANGE_LOAD_DUMP  = 90,
    SHIFT_CHANGE_QTY = 100,
    SHIFT_CANCEL_TRUCKS = 110
}
