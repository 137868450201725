
  import { SessionStorageEnum } from "@/utils/SessionStorageEnum";
  import { Options, Vue } from "vue-class-component";
  import { Model } from "vue-property-decorator";
  import {NotificationFilters} from "@/modules/notifications/pages/notifications/NotificationFilters";
  import {plainToClass} from "class-transformer";
  import moment from "moment";

  @Options({})
  export default class extends Vue {
    @Model("filters")
    advFilters: NotificationFilters;

    public types: any[]

    get fromDate() {
      const from = this.advFilters?.from_to?.[0];
      return from ? moment(from).format("YYYY/MM/DD") : "----/--/--";
    }

    get toDate() {
      const to = this.advFilters?.from_to?.[1];

      return to ? moment(to).format("YYYY/MM/DD") : "----/--/--";
    }

    openCalendar(event) {
      (this.$refs as any).op?.toggle(event);
    }

    hideCalendar() {
      (this.$refs as any).op?.hide();
    }

    emitFilters() {
      this.saveAdvancedFilters();
      this.$emit("change", this.advFilters);
    }

    resetDateFilter() {
      this.advFilters.from_to = null;
      this.emitFilters();
    }

    private saveAdvancedFilters() {
      sessionStorage.setItem(
        SessionStorageEnum.NOTIFICATIONS_ADVANCED_FILTERS,
        JSON.stringify(this.advFilters)
      );
    }

    private restoreAdvancedFilters() {
      const fromStorage = JSON.parse(
        sessionStorage.getItem(SessionStorageEnum.NOTIFICATIONS_ADVANCED_FILTERS)
      );

      this.advFilters = fromStorage
          ? plainToClass(NotificationFilters, fromStorage)
          : this.advFilters;
    }

    created() {
      this.restoreAdvancedFilters();
    }
  }
